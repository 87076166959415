const getValue = (key, defaultValue = {}) => {
  try {
    // read value from local storage
    const item = window.localStorage.getItem(key);
    return item ? JSON.parse(item) : defaultValue;
  } catch (error) {
    return defaultValue;
  }
};
const setValue = (key, value) => {
  try {
    window.localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {}
};
const checkShopListFlow = () => {
  let host = window.location.hostname;
  let companyName = "bestatservices";
  // To check in local change origin to current url
  let origin = "https://bestatservices.com";
  if (host !== "localhost") {
    origin = window.location.origin;
  }
  let client = origin.split("/");
  client = client[client.length - 1].split(".");
  companyName = client[0];
  if (companyName === "app" || client[0] === "bestatservices") {
    return true;
  } else {
    return false;
  }
};

const removeDuplicates = (arr, type) =>
  arr.filter((v, i, a) => a.findIndex((v2) => v2[type] === v[type]) === i);

export { getValue, setValue, removeDuplicates,checkShopListFlow };
