import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ihi2,ihi3,ihi4,ihi5,ihi6,ihi7,ihi8,ihi9,ihi10,ihi11,ihi12,ihi13, ihvimg1, ihvimg2, ihvimg3, ihvimg4, ihvimg5 } from "../../../assets/img";
import { useDispatch, useSelector } from "react-redux/es/exports";
import { data } from "../../edit/data";
import { fetchHomeJsonList } from "../../../redux/Actions";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import axios from "../../../config";

function SectionTwo () {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const base_url = process.env.REACT_APP_BACKEND_URL;
  const [areaCovered, setAreaCovered] = useState<any | null>({});

  const shopHomeData: any = useSelector<any>((state) => state?.shopHome);
  useEffect(() => {
    dispatch(fetchHomeJsonList(data));
  }, [data, dispatch]);
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;
  const homedata: any = useSelector<any>((state) => state?.homeJsonList);

  const [isOpen, setIsOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");

  // Function to open the zoom preview
  const openPreview = (image: any) => {
    setCurrentImage(image);
    setIsOpen(true);
  };

  // Function to close the zoom preview
  const closePreview = () => {
    setIsOpen(false);
  };

  useEffect(() => {}, [homedata]);
  useEffect(() => {
    let currentShopId = localStorage.getItem("currentShopHome") || null;
    const url = new URL(window.location.href);
    const url_array = url.pathname.split("/").filter(Boolean);
    let shop_name = url_array[1];
    const headers = {
      Accept: "application/json",
    };

    axios.get(`${base_url}/api/areas_covered?shop_name=${shop_name}&shop_id=${currentShopId}`, {
        headers: headers,
      })
      .then((e:any) => {
        setAreaCovered({ data: e.data });
      });
  }, []);
  const popAreadCovered = (areaList: any) => {
    var test = Object.keys(areaList).slice(0, 6).map((location, locationindex) => {
      let cityArr = areaList[location].toString().split(",").slice(0, 1);
        console.log("city",cityArr)
      return (
        <>
        <div key={locationindex}>
          {cityArr.map((city: any, cityIndex: any) => (
            <li key={cityIndex}>
              <a
                // href="#"
                // onClick={(e) => {
                //   e.preventDefault();
                //   navigate("/dry-cleaners/" + city);
                // }}
              >
                {city}
              </a>
            </li>
          ))}
          
          </div>
        </>
      );
    });

    return test;
  };
 const popAreadCovered2 = (areaList: any) => {
    if (Object.keys(areaList).length > 6) {
    return (
        <button key="showMore" className="ftransbtn" onClick={navigateToAreaCovered}>
            Show More
        </button>
    );
} 
  };
  const navigateToAreaCovered = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
    const finalResult = shopName
      .trim()
      .replace(/\s(.)/g, (match: any) => match.toUpperCase())
      .replace(/\s/g, "")
      .replace(/^(.)/, (match: any) => match.toLowerCase());
    if (shopName) {
      navigate(`/shop/${finalResult}/areaCovered`);
    } else {
      navigate("/areaCovered");
    }
  } else {
    navigate("/areaCovered");
  }
  };

  const images = [ihvimg1, ihvimg2, ihvimg3, ihvimg4, ihvimg5];

  return (
    <>
        <section id="t11hiw">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="section-h2">How It Works</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-4">
                        <div className="hiw-grid text-center">
                            <img src={ihi2} alt=""/>
                            <div className="hiw-desc">
                                <span>1.</span>
                                <p>Choose <strong>where</strong> and <strong>when</strong> you’d like us to visit and serve your needs.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="hiw-grid text-center">
                            <img src={ihi3} alt=""/>
                            <div className="hiw-desc">
                                <span>2.</span>
                                <p>We’ll <strong>visit</strong> your <strong>doorstep</strong> and <strong>fulfil</strong> your needs according to your requirements.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="hiw-grid text-center">
                            <img src={ihi4} alt=""/>
                            <div className="hiw-desc">
                                <span>3.</span>
                                <p>We deliver your items, <strong>cleaned</strong> within the time required per service.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* <section className="ihrsecbl-bgwhite pb-0">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="section-h2">What Our Client Say</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                    <Swiper
                        spaceBetween={15}
                        navigation={true}
                        modules={[Navigation]}
                        breakpoints={{
                        640: {
                            slidesPerView: 1,
                        },
                        768: {
                            slidesPerView: 2,
                        },
                        1024: {
                            slidesPerView: 3,
                        },
                        }}
                        className="home-slider"
                    >
                    {homedata?.theme_3?.home?.section_12?.review_card.map(
                    (items: any,index: number) => {
                        return (
                        <SwiperSlide>
                            <div className="sh11-testimonial-card" key={index}>
                            <div className="sh11-profile-user">
                                <a className="sh11-profile-user-img">
                                <img src={items.reviewer_pic} className="rounded-circle" alt="" />
                                </a>
                                <div className="sh11-profile-user-info">
                                <h6>
                                    <a>{items.reviewer_name}</a>
                                </h6>
                                <div className="sh11-profile-rating">
                                    <span className="secondary-text">
                                    {Array.from({ length: 5 }, (_, i) => (
                                        <i
                                        key={i}
                                        className={`fas fa-star ${i < items.rating ? 'text-secondary' : 'text-secondary text-lightg'}`}
                                        ></i>
                                    ))}
                                    </span>
                                    <span>{items.rating}</span>
                                </div>
                                </div>
                            </div>
                            <h5>{items.review_highlight}</h5>
                            <p>{items.review}</p>
                            </div>
                        </SwiperSlide>
                        );
                    }
                    )}
                    </Swiper>
                    </div>
                </div>
            </div>
        </section> */}
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="section-h2">Pricing</h2>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-3">
                        <div className="ihr-sec-grid-3">
                            <img src={`${shopHomeData?.home_page_settings?.shop_home_page_image.logo[0].file_name}`} alt="" />
                            <h3>WASH</h3>
                            <p>HOT & COLD WATER JET WASHING</p>
                            <strong>From £49.99</strong>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="ihr-sec-grid-3">
                            <img src={`${shopHomeData?.home_page_settings?.shop_home_page_image.logo[0].file_name}`} alt="" />
                            <h3>PRESSURE WASH</h3>
                            <p>PROFESSIONAL PRESSURE WASHING</p>
                            <strong>From £49.99</strong>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="ihr-sec-grid-3">
                            <img src={`${shopHomeData?.home_page_settings?.shop_home_page_image.logo[0].file_name}`} alt="" />
                            <h3>VALETING</h3>
                            <p>MINI VALETING</p>
                            <strong>From £49.99</strong>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="ihr-sec-grid-3">
                            <img src={`${shopHomeData?.home_page_settings?.shop_home_page_image.logo[0].file_name}`} alt="" />
                            <h3>VALETING</h3>
                            <p>FULL VALETING</p>
                            <strong>From £99.99</strong>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        {/* Slider with Images */}
        <section>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="section-h2">Gallery</h2>
                    </div>
                </div>
                <Swiper
                    spaceBetween={15}
                    navigation={true}
                    modules={[Navigation]}
                    pagination={{
                    el: ".swiper-pagination",
                    clickable: true
                    }}
                    slidesPerView={3}
                    onClick={(swiper) => {
                        const clickedSlide = swiper.clickedSlide;
                        if (clickedSlide) {
                          const image = clickedSlide?.querySelector("img")?.getAttribute("src");
                          openPreview(image);
                        }
                      }}
                    >
                    {images.map((image, index) => (
                        <SwiperSlide key={index}>
                            <img src={image}
                                alt={`Slide ${index + 1}`}
                                style={{
                                    width: "100%",
                                    height: "500px",
                                    objectFit: "cover",
                                    cursor: "pointer",
                                    borderRadius: "10px",
                                }}
                            />
                        </SwiperSlide>
                        ))
                    }
                </Swiper>
                {/* Modal for zoom preview */}
                {/* Custom Modal for Zoomed Image */}
                {isOpen && (
                    <div
                    style={{
                        position: "fixed",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        background: "rgba(0, 0, 0, 0.9)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        zIndex: "1000",
                    }}
                    onClick={closePreview} // Close modal on overlay click
                    >
                    <div
                        style={{
                        position: "relative",
                        maxWidth: "90%",
                        maxHeight: "90%",
                        }}
                        onClick={(e) => e.stopPropagation()} // Prevent closing when clicking the image
                    >
                        <img
                        src={currentImage}
                        alt="Zoomed Preview"
                        style={{
                            width: "100%",
                            height: "570px",
                            borderRadius: "10px",
                        }}
                        />
                        <button
                        onClick={closePreview}
                        style={{
                            position: "absolute",
                            top: "10px",
                            right: "10px",
                            background: "white",
                            color: "black",
                            border: "none",
                            borderRadius: "50%",
                            width: "30px",
                            height: "30px",
                            cursor: "pointer",
                        }}
                        >
                        ✕
                        </button>
                    </div>
                    </div>
                )}
            </div>
        </section>
        <section className="ihrsecbl-valet">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-center">
                            {/* <img src={ihi13} alt="" className="mb-3" /> */}
                            <h2 className="section-h2 section-h2w">The Professional Pressure Washing Service <br/>directly at your doorstep</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="ihr-sec-grid-4">
                            <p>we are a network of the very best car washers. </p>
                            <p>We provide the finest car washing services, combined with exceptional customer service and convenient doorstep services suited perfectly to your schedule.  </p>
                            <p>Fully trade insurance on place and fully trained driver to help pick up your vehicle and repair in a short period of time.</p>
                            <p>We provide even replace hire cars to the customers if they require so.</p>
                        </div>
                        <div className="ihr-sec-grid-4">
                            <h3>MOBILE CAR WASH AND CAR SERVICES - HOW DOES IT WORK?</h3>
                            <p>We are transitioning our app to focus exclusively on car-related services, operating from our garage in Finsbury Park. Our offerings include car servicing, mechanical repairs, diagnostics, and car washing, with convenient pick-up and drop-off options for customers. Additionally, we provide other services like gardening and cleaning (e.g., driveways, patios). The app will integrate all these features, with the flexibility to update or add new functionalities in the future. Let us know if you have any suggestions or additional requirements!</p>
                            {/* <p>The service is simple: we visit your location, professionally clean and wash your vehicle, and deliver them back to you within the time required per service.</p> */}
                            {/* <p>We offer flexible time slots, allowing us to work around your schedule and deliver the service beautifully washed and finished exactly when you need them.</p>
                            <p>Car wash take 1 hour to clean or full valeting your car at your desired location in your convenience time slot.</p> */}
                            {/* <p>If you’re especially pressed for time, you can opt for same day laundry delivery on items collected before 10am.</p> */}
                        </div>
                        <div className="ihr-sec-grid-4">
                            <h3>TWO BROTHERS PROFESSIONAL PRESSURE WASHERS</h3>
                            <p>Founded in 2014, we are the first on demand car washing service in London, and has since expanded its horizons to operate in cities across
the UK.</p>
                            <p>We work with a network of the best car washing services, ensuring the best quality cleaning, washing, valeting and more.</p>
                            <p>Every service is treated with care and respect by our master car washers, who provide world-class car washing services time and time again. </p>
                            <p>If you’re looking to find the pressure washer near you, then look no further than us. </p>
                        </div>
                        <div className="ihr-sec-grid-4">
                            <h3>OUR LOCATIONS</h3> 
                            <ul>
                                <>
                                {Object.keys(areaCovered).length > 0 &&
                                popAreadCovered(areaCovered?.data.data.areas_covered)}
                                
          {Object.keys(areaCovered).length > 0 &&
                                popAreadCovered2(areaCovered?.data.data.areas_covered)}
                                </>
                            </ul>
                            
                        </div>
                        <div className="ihr-sec-grid-4">
                            <h3>EXPERT CAR WASHING SERVICES</h3>
                            <p>We have a range of premium car washing and valeting services on offer, from <strong>pressure washing and jet washing your favourite vehicles, </strong>to expert<strong> valeting services</strong> on any of your vehicles, and everything in between.</p>
                            {/* <p>For more information, visit our <strong><a href="">services page, </a></strong> or reach out to our friendly Customer Care team with your queries.</p> */}
                        </div>
                        <div className="ihr-sec-grid-4">
                            <h3>WE CLEAN DRIVEWAYS, PATIOS, DECKING & MORE</h3>
                            <p>Bring back the sparkle to your outdoor spaces with our professional cleaning services. From grime-covered driveways to weathered decking, we ensure a spotless and refreshed finish every time.</p>
                            {/* <p>For more information, visit our <strong><a href="">services page, </a></strong> or reach out to our friendly Customer Care team with your queries.</p> */}
                        </div>
                        <div className="ihr-sec-grid-4">
                            <h3>Book Our Service with a Minimum Spend of £20!</h3>
                            <p>Quality services tailored to your needs, starting at just £20. Ensure your car shines without breaking the bank. Book today and let us take care of the mess while you enjoy the results!</p>
                            {/* <p>For more information, visit our <strong><a href="">services page, </a></strong> or reach out to our friendly Customer Care team with your queries.</p> */}
                        </div>
                        <div className="ihr-sec-grid-4">
                            <h3>One-Stop Solution: Car Services & Laundry Care!</h3>
                            <p>We go beyond just taking care of your car. Now, you can enjoy our convenient laundry services, too! From spotless vehicles to fresh, clean clothes, we’ve got you covered.</p>
                            <p>We pick up dirty laundry or curtains or carpets to pick and drop off with 24 hours period.</p>
                        </div>
                        <div className="ihr-sec-grid-4">
                            <h3>CAR WASHING AND VALETING BLOG</h3>
                            <p>Follow our <strong><a href={blogUrl}> blog</a></strong> to stay up to date with industry and sustainability news and keep up with the exciting things we're doing as a
business. You can also find plenty of tips and tricks and in-depth step by step guides to help you navigate all kinds of car washing and valeting on our blog.</p>
                        </div>
                    </div>
                </div>
                <div className="ihisecin">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="text-center">
                                <h2 className="section-h2 section-h2w">ARE MY VEHICLES SAFE WITH<br/>YOUR SERVICE?</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-md-3">
                            <div className="ihr-sec-grid-5">
                                <div className="ihr-sec-count">121</div>
                                <p>Cars washed in 2024.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="ihr-sec-grid-5">
                                <div className="ihr-sec-count">121</div>
                                <p>Pressure Washes.</p>
                            </div>
                        </div>
                        <div className="col-md-3">
                            <div className="ihr-sec-grid-5">
                                <div className="ihr-sec-count">99.9%</div>
                                <p>Valeting without an issue.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="text-center">
                            <h2 className="section-h2 section-h2w">OUR PROMISE TO YOU</h2>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-11">
                        <div className="ihr-sec-grid-6">
                            <p>We do everything to minimise any risk, but on the very rare occasion an item is damaged, we’ll hold our hands up and admit the mistake and
either fix it or compensate you for any damage or loss</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>
  );
}

export default SectionTwo;
