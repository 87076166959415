import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { checkShopListFlow } from "../../../utility";
// import { shop_data } from "../edit/data";
import { ihi14, ihi15, ihi16, ihi17 } from "../../../assets/img";
import SampleVideo from "./../../../images/sample.mp4";

function FooterShopEleven() {
  const navigate = useNavigate();
  const Url = window.location.host;
  const blogUrl = `https://blog.${Url}`;

  const base_url = process.env.REACT_APP_BACKEND_URL;
  // const base_url = "http://www.bestatlaundry.test";

  const [settings, setSettings] = useState<any>({});

  const appStorel =
    Object.keys(settings).length > 0 &&
    settings.WebmasterSettings.app_store_link;
  const playStore =
    Object.keys(settings).length > 0 &&
    settings.WebmasterSettings.play_store_link;
  const facebook =
    Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link1;
  const twitter =
    Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link2;
  const instagram =
    Object.keys(settings).length > 0 && settings.WebsiteSettings.social_link6;
  const dispatch = useDispatch<any>();

  const [areaCovered, setAreaCovered] = useState<any | null>({});

  const settingsData: any = useSelector<any>((state) => state?.settings);

  const homedata: any = useSelector<any>((state) => state?.shopHomeJsonList);
  // const homedata:any = shop_data

  // useEffect(() => {
  //   // if (Object.keys(settings).length === 0 && settingsData == undefined) {
  //   //   axios({
  //   //     method: "get",
  //   //     url: `${base_url}/api/settings`,
  //   //     headers: {
  //   //       Accept: "application/json",
  //   //       "Access-Control-Allow-Methods": "GET, POST",
  //   //     },
  //   //   })
  //   //     .then((response) => {
  //   //       if (response.status != 401) {
  //   //         setSettings(response.data.data);
  //   //         dispatch(setSettingsAll(response.data.data));
  //   //       }
  //   //     })
  //   //     .catch(function (error) {});
  //   // }

  //   const headers = {
  //     Accept: "application/json",
  //   };

  //   axios
  //     .get(`${base_url}/api/areas_covered`, {
  //       headers: headers,
  //     })
  //     .then((e) => {
  //       setAreaCovered({ data: e.data });
  //     });
  // }, []);

  const navigateToAreacovered = () => {
    if (checkShopListFlow()) {
      const shopName: any = localStorage.getItem("currentShopName");
      if (shopName) {
        const finalResult = shopName
          .trim()
          .replace(/\s(.)/g, (match: any) => match.toUpperCase())
          .replace(/\s/g, "")
          .replace(/^(.)/, (match: any) => match.toLowerCase());

        navigate(`/shop/${finalResult}/areaCovered`);
      } else {
        navigate("/areaCovered");
      }
    } else {
      navigate("/areaCovered");
    }
  };
  const navigateToTermsAndConditions = () => {
    const shopName: any = localStorage.getItem("currentShopName");
    if (shopName) {
      const finalResult = shopName
        .trim()
        .replace(/\s(.)/g, (match: any) => match.toUpperCase())
        .replace(/\s/g, "")
        .replace(/^(.)/, (match: any) => match.toLowerCase());
      if (shopName) {
        navigate(`/shop/${finalResult}/termsAndConditions`);
      } else {
        navigate("/termsAndConditions");
      }
    } else {
      navigate("/termsAndConditions");
    }
  };
  const navigateToAboutUs = () => {
    if (checkShopListFlow()) {
      const shopName: any = localStorage.getItem("currentShopName");
      if (shopName) {
        const finalResult = shopName
          .trim()
          .replace(/\s(.)/g, (match: any) => match.toUpperCase())
          .replace(/\s/g, "")
          .replace(/^(.)/, (match: any) => match.toLowerCase());

        navigate(`/shop/${finalResult}/aboutUs`);
      } else {
        navigate("/aboutUs");
      }
    } else {
      navigate("/aboutUs");
    }
  };

  const popAreadCovered = (areaList: any) => {
    var test = Object.keys(areaList).map((location, locationindex) => {
      let cityArr = areaList[location].toString().split(",");

      return (
        <>
          {cityArr.map((city: any) => (
            <li>
              <a
                href="#"
                onClick={(e) => {
                  e.preventDefault();
                  navigate("/dry-cleaners/" + city);
                }}
              >
                {city}
              </a>
            </li>
          ))}
        </>
      );
    });

    return test;
  };
  let shopURL = window.location.href;
  var dateObj = new Date();
  var year = dateObj.getUTCFullYear();

  return (
    <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-md col-sm-12">
              <div className="fldesc py-0">
                  <p>
                    The spring fresh smell, razor sharp pressing, and impeccable cleaning is driven by our expert car washers, passion and pride in what they do. We are the leading on demand valeting service offering convenient schedules.
                  </p>  
                </div>            
            </div>
            <div className="col-md col-sm-12">
              <div className="fmenu">
                <h4>Quick Links</h4>
                <ul>
                <li>
                  <a
                    href=""
                    onClick={(e) => {
                      e.preventDefault();
                      navigateToAboutUs();
                    }}
                  >
                    About Us
                  </a>
                </li>
                <li>
                  <a
                    onClick={navigateToTermsAndConditions}
                    style={{ cursor: "pointer" }}
                  >
                    Terms and conditions
                  </a>
                </li>
                {/* <li><a href=''>Enquiry</a></li> */}
                {/* <li><a href=''>Call a Schedule</a></li> */}
              </ul>
              </div>
              
              
            </div>
            <div className="col-md col-sm-12">
            <div className="fmenu">
              <h4>Explore</h4>
              <ul>
                <li>
                  <a href="#t11hiw">How It Works</a> 
                </li>
                {/* <li><a href=''>Help Center</a></li> */}
                <li>
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault();
                      navigateToAreacovered();
                    }}
                  >
                    Locations
                  </a>
                </li>
                {/* <li><a href=''>Carreers</a></li>
                <li><a href=''>Special Requests</a></li> */}
              </ul>
              </div>
            </div>
            <div
          className="modal fade"
          id="videomodal"
          aria-hidden="true"
          data-bs-keyboard="false"
        >
          <div className="modal-dialog modal-lg modal-dialog-centered">
            <div className="modal-content modal-video">
              <video width="100%" height="auto" autoPlay loop muted>
                <source src={SampleVideo} type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
          </div>
            {/* <div className="row">
                <div className="col-md">                        
                    <div className="fmenu">
                        <h4>Services</h4>
                        <ul>
                            <li><a href="#">Gift Vouchers</a></li>
                            <li><a href="#">Dry Cleaning</a></li>
                            <li><a href="#">Household textiles</a></li>
                            <li><a href="#">Shirt Service</a></li>
                            <li><a href="#">Laundry Services</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md">                        
                    <div className="fmenu">
                        <h4>About Us</h4>
                        <ul>
                            <li><a href="#">Gift Vouchers</a></li>
                            <li><a href="#">Dry Cleaning</a></li>
                            <li><a href="#">Household textiles</a></li>
                            <li><a href="#">Shirt Service</a></li>
                            <li><a href="#">Laundry Services</a></li>
                        </ul>
                    </div>
                </div>
                <div className="col-md d-none">                        
                    <div className="fmenu">
                        <h4>Selected Locations</h4>
                        <ul className="fmenu-list">
                            <li><a href="#">London</a></li>
                            <li><a href="#">East London</a></li>
                            <li><a href="#">North London</a></li>
                            <li><a href="#">South West London</a></li>
                            <li><a href="#">Brighton</a></li>
                            <li><a href="#">Canary Wharf</a></li>
                            <li><a href="#">London</a></li>
                            <li><a href="#">East London</a></li>
                            <li><a href="#">North London</a></li>
                            <li><a href="#">South West London</a></li>
                            <li><a href="#">Brighton</a></li>
                            <li><a href="#">Canary Wharf</a></li>
                            <li><a href="#">London</a></li>
                            <li><a href="#">East London</a></li>
                            <li><a href="#">North London</a></li>
                            <li><a href="#">South West London</a></li>
                            <li><a href="#">Brighton</a></li>
                            <li><a href="#">Canary Wharf</a></li>
                            <li><a href="#">London</a></li>
                            <li><a href="#">East London</a></li>
                            <li><a href="#">North London</a></li>
                            <li><a href="#">South West London</a></li>
                            <li><a href="#">Brighton</a></li>
                            <li><a href="#">Canary Wharf</a></li>
                        </ul>
                    </div>
                </div>
            </div> */}
            {/* <div className="row">
                <div className="col-md-12">
                    <div className="fldesc">
                        <p>
                        The spring fresh smell, razor sharp pressing, and impeccable cleaning is driven by our expert dry cleaners' passion and pride in what they do. we are the leading on demand laundry service offering
convenient dry cleaning pickup and delivery with a 24 hour turnaround.
                        </p>
                        <p>
                        We're proud to partner with a network of master cleaners across London. Our cleaners have decades of experience, use the finest materials, and have the best machinery. With us, it's the
little things that make a difference. From our same day or next day laundry delivery, to the precision and care given to each individual garment, to our friendly drivers who collect and deliver straight to your
doorstep, choosing our service means choosing peace of mind.
                        </p>
                    </div>
                </div>
            </div> */}
            <div className="row d-none">
                <div className="col-md">
                    <div className="flapp">
                        <span>Download our new app:</span>
                        <ul className="flapplinks">
                            <li>
                                <a target="_blank" href={appStorel}>
                                    <img src={ihi16} alt="" />
                                </a>
                                
                                {shopURL?.includes("starcraftdrycleaners") && (  
                                  <div>
                                  {/* <img src={starcraftios} alt="" className="qr11"/> */}
                                  </div>
                                )}
                                
                            </li>
                            <li>
                                <a target="_blank" href={playStore}>
                                    <img src={ihi17} alt="" />
                                </a>
                                
                                {shopURL?.includes("starcraftdrycleaners") && (  
                                  <div>
                                  {/* <img src={starcraftandroid} alt="" className="qr11"/>                                   */}
                                  </div>
                                )}
                            </li>
                        </ul>
                    </div>
                </div>
                {/* <div className="col-md-9">
                    <div className="flsubsc">
                        <label>Subscribe to our newsletter:</label>
                        <div className="flsubsc-inp-group">
                            <input type="text" />
                            <button className="btn">Subscribe</button>
                        </div>
                    </div>
                </div> */}
            </div>
            <div className="row">
                <div className="col-md-6">
                    <div className="flcredits">
                        {/* <img src={ihi14} alt=""/>                             */}
                        <p>© {year} {Object.keys(settings).length > 0 && settings?.WebsiteSettings?.site_title_en}.All rights reserved. | Powered by &nbsp;
                <a href="http://www.bestatservices.com" target="blank">
                  www.bestatservices.com
                </a></p>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="flpaymethods">
                        <p>Accepted payment methods:</p>
                        <img src={ihi15} alt=""/> 
                    </div>                        
                </div>
            </div>
            
    </div>
    </footer>
  );
}

export default FooterShopEleven;
