import axios from "axios";
import { toast } from "react-toastify";

const token = localStorage.getItem("token");
axios.interceptors.request.use(
  async (config) => {
    let host = window.location.hostname;
    // while checking in local change origin,domain and companyName to current client
    // if not in localhost it will get the origin, domain and companyName from URL Eg:(https://demo1.bestatlaundry.com)
    let origin = "https://demo1.bestatlaundry.com";
    let domain = "com";
    let companyName = "demo1";
    if (host !== "localhost") {
      origin = window.location.origin;
      let client = origin.split("/");
      client = client[client.length - 1].split(".");
      companyName = client[0];
      if (client.length === 3) {
        domain = `${client[client.length - 2]}.${client[client.length - 1]}`;
      } else if (client.length > 3) {
        domain = `${client[client.length - 3]}.${client[client.length - 2]}.${
          client[client.length - 1]
        }`;
      } else {
        domain = client[client.length - 1];
      }
    }

    if (host === "localhost") {
      // config.baseURL = `https://api.bestatlaundry.${domain}`;
      config.baseURL = `https://api.app.bestatservices.com`;
      //config.baseURL = `https://api.bestatsaloon.com`;
      //config.baseURL = `https://api.rebeccabeauty.co.uk/`;
    } else {
      config.baseURL = `https://api.${companyName}.${domain}`;
    }
    config.headers = {
      ...config.headers,
    };
    let URL = window.location.href.split("/");

    if (URL.includes("shop")) {
      let tenant_id = localStorage.getItem("tenant_id");
      let params = `?shopHome=true&tenant_id=${tenant_id}`;
      let hasQuery = config.url.split("?");
      if (hasQuery.length > 1) {
        params = `&shopHome=true&tenant_id=${tenant_id}`;
      }
      config.url = config.url + params;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // toast(response.data.Response.response_message);
    if (error.response.status != undefined && error.response.status === 401) {
      const isCompleteOrder = localStorage.getItem("isCompleteOrder");
      if (localStorage.getItem("isCompleteOrder") !== "1") {
        toast.error(
          "Please login or register to access the booking page."
          // error.response.data.Response.response_message + " " + error?.message
        );
      }
      // localStorage.clear();
      // navigate("/login")
      // window.location.href = "/sessionTimeOut";
      // window.location.href = "/login";
      setTimeout(() => {
        if (isCompleteOrder) {
          const order_id = localStorage.getItem("order_id");
          localStorage.clear();
          localStorage.setItem("isCompleteOrder", "1");
          localStorage.setItem("order_id", order_id);
        } else {
          localStorage.clear();
        }
        window.location.href = "/login";
      }, 3000);
    }
    if (error.response.status === 403) {
      // localStorage.clear();
      window.location.href = "/connexion";
    }

    // reject with error if response status is not 403
    return Promise.reject(error);
  }
);

export default axios;
